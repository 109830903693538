<template>
  <div>
    <el-dialog title="评论" :modal-append-to-body="false" :visible.sync="dialogVisible" :width="width" @close="close"
      :custom-class="'evaluate-dialog'">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
        <el-form-item prop="comment">
          <el-input type="textarea" v-model="ruleForm.comment" placeholder="请填写评论"></el-input>
        </el-form-item>
        <div class="tag" style="margin-bottom: 20px">
          <p class="title">推荐回复</p>
          <div class="tag_reply">
            <div class="tag_list">
              <el-tag style="cursor: pointer" @click="chooseTag(item)" v-for="(item, index) in tagList" :key="index">{{
                item
                }}</el-tag>
              <div class="tag_add" v-for="item in CommentList" :key="item.id" @click="chooseTag(item.tagName)"
                :class="{active: !isDelete,die:isDelete}">
                {{item.tagName}}
                <img src="@/assets/ioc/minus-circle.png" alt="" @click.stop="deleteComment(item.id)" v-if="isDelete">
              </div>

            </div>
            <div class="tag_operate">
              <img src="@/assets/ioc/plus-add.png" alt="" @click="addComment = true" v-if="!isDelete">
              <img src="@/assets/ioc/minus-circle.png" alt="" @click="isDelete = true" v-if="!isDelete">
              <img src="@/assets/ioc/times-circle.png" alt="" @click="isDelete = false" v-if="isDelete">
            </div>
          </div>
        </div>
        <div class="file">
          <div>
            <el-upload :action="uploadAction" :show-file-list="false" :data="uploadData" :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 1)
            " multiple :before-upload="(file) => beforeAvatarUpload(file, 1)">
              <i class="el-icon-picture-outline"></i>
              <span>图片</span>
            </el-upload>
          </div>
          <div>
            <el-upload :action="uploadAction" :show-file-list="false" :data="uploadData" multiple :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, 2)
            " :before-upload="(file) => beforeAvatarUpload(file, 2)">
              <i class="el-icon-link"></i>
              <span>附件</span>
            </el-upload>
          </div>
        </div>
        <div class="picture_list">
          <div v-for="(item, index) in ruleForm.picture" :key="index">
            <el-image style="width: 50px; height: 50px" :src="item.url" :preview-src-list="previewList">
            </el-image>
            <div class="del_box">
              <i class="el-icon-close" style="cursor: pointer; color: #fff" @click="delPicture(index)"></i>
            </div>
          </div>
        </div>
        <div class="attachment_list">
          <div v-for="(item, index) in ruleForm.attachment" :key="index">
            <p>{{ item.name }}</p>
            <div>
              <el-link @click="preview(item)">预览</el-link><i class="el-icon-delete" style="cursor: pointer"
                @click="delAttachment(index)"></i>
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">

        <div @click="dialogVisible = false" class="cancel">
          取消
        </div>
        <div @click="debounceSubmitForm('ruleForm')" class="submit">
          确定
        </div>
      </span>
      <!-- 文件预览 -->
      <FilePreviewDialog :file-url="fileUrl" :fileName="fileName" :dialog-visible="openFilePreview"
        @closePreviewDialog="closePreviewDialog"></FilePreviewDialog>
    </el-dialog>

    <el-dialog title="新增回复" :modal-append-to-body="false" :visible.sync="addComment" width="30%" @close="reply = ''"
      :custom-class="'evaluate-dialog'">
      <el-input v-model="reply" placeholder="请输入"></el-input>
      <span slot="footer" class="dialog-footer">
        <div @click="debouncedAddReply" class="submit" style="width: 100%;margin-left: 0px;">
          确定
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { debounce } from 'lodash-es';
  import FilePreviewDialog from "./FilePreviewDialog.vue";
  import { createComment, addTags, getTagsList, deleteTags } from "@/api/bpm/flow";
  import axios from "axios";
  import { uploadURLFromRegionCode, init, config } from "@/utils/qiniuUploader";
  export default {
    // props: ["taskId", "id", "tasks", "currentTaskId"],
    props: {
      taskId: {
        type: String,
        default: null,
      },
      id: {
        type: String,
        default: null,
      },
      tasks: {
        type: Array,
        default: [],
      },
      InstanceData: {
        type: Array,
        default: [],
      },
      currentTaskId: {
        type: String,
        default: null,
      },
      width: {
        type: String,
        default: '30%'
      },
      modelId: {
        type: String,
        default: null,
      }
    },
    components: { FilePreviewDialog },
    data() {
      return {
        debouncedAddReply: null,  // 初始化为 null
        debouncedSubmit: null,
        tagList: [
          "已阅",
          "收到",
          "已核对",
          "合格",
          "情况属实",
          "确认",
          "已复核",
          "知悉",
          "辛苦了",
        ],
        openFilePreview: false,
        fileUrl: null, // 预览本地文件，文件放置与public文件夹下
        fileName: null, //
        loading: false,
        dialogVisible: false,
        ruleForm: {
          picture: [],
          attachment: [],
          comment: "",
        },
        rules: {
          comment: [{ required: true, message: "请输入评论", trigger: "change" }],
        },
        maxSize: 100 * 1024 * 1024, // 最大文件大小（10MB）
        uptoken: "", //七牛云token
        uploadAction: "", //图片上传
        uploadData: {}, //上传参数
        fileList: [], //图片上传列表
        uploadUrl: "", // 上传地址
        status: 0,
        addComment: false,
        reply: "",
        CommentList: [],
        isDelete: false,
      };
    },
    computed: {
      previewList() {
        return this.ruleForm.picture.map((e) => e.url);
      },
    },
    async created() {
      this.debouncedSubmit = debounce(this.submitForm, 500);
      this.debouncedAddReply = debounce(this.addReply, 500);
      this.getuptoken();
      await this.getCommentList();
    },
    methods: {
      //获取评论回复列表
      async getCommentList() {
        const data = await getTagsList(this.$store.state.imWorkbench.userId);
        this.CommentList = data.data;
      },
      //删除回复
      async deleteComment(id) {
        const data = await deleteTags(id);
        this.$modal.msgSuccess("删除成功");
        await this.getCommentList();
      },
      preview(e) {
        window.ipcRenderer
          .invoke("win-operate", {
            win: "ChildWin",
            operate: "start",
            argu: {
              width: 800,
              height: 550,
              route: `/ImagePreview?src=${e.url}`,
            },
          })
          .then(() => {
            this.$message("预览失效");
          });
      },
      FilePreviewDialogHandle(type, file) {
        this.openFilePreview = !this.openFilePreview;
        this.fileUrl = file.url;
        this.fileName = file.name;
      },
      closePreviewDialog() {
        this.openFilePreview = !this.openFilePreview;
      },
      getFileType(fileUrl) {
        if (fileUrl !== "" && fileUrl != null && fileUrl !== undefined) {
          const fileType = fileUrl.split(".").pop().toLowerCase(); // 文件的扩展名（格式）

          return fileType;
        } else {
          return "docx";
        }
      },
      close() {
        this.ruleForm = {
          picture: [],
          attachment: [],
          comment: "",
        };
        this.$refs.ruleForm.resetFields();
      },
      chooseTag(text) {
        this.ruleForm.comment = text;
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.createComment();
          } else {
            return false;
          }
        });
      },
      // 防抖
      debounceSubmitForm(formName) {
        this.debouncedSubmit(formName);
      },
      async createComment() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        const Inst = this.InstanceData.length > 0 ? this.InstanceData[0].id : null;
        let obj = {
          taskId: Inst,
          processInstanceId: this.id,
          modelId: this.modelId,
          ...this.ruleForm,
        };
        if (this.status == 1) {
          this.tasks.reverse();
          obj.taskId = this.tasks[0].id;
        }

        this.loading = true;
        const { data } = await createComment(obj);
        this.loading = false;
        this.dialogVisible = false;
        loading.close();
        this.$message({
          message: "评论成功",
          type: 'success',
          duration: 1000,
          onClose: () => {
            this.$emit("createComment");
          }
        });
      },
      delAttachment(index) {
        this.ruleForm.attachment.splice(index, 1);
      },
      delPicture(index) {
        this.ruleForm.picture.splice(index, 1);
      },
      beforeAvatarUpload(file, type) {
        // type 1 图片  2附件
        var img = file.name.substring(file.name.lastIndexOf(".") + 1);
        const suffix = img === "jpg";
        const suffix2 = img === "png";
        const suffix3 = img === "jpeg";
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (type == 1) {
          if (!suffix && !suffix2 && !suffix3) {
            this.$message.error("只能上传图片！");
            return false;
          }

          if (!isLt2M) {
            this.$message.error("上传头像图片大小不能超过 10MB!");
            return false;
          }
        }
        this.uploadData.key = Date.now() + "_" + file.name;
      },
      handleDownload(file) {

      },
      // 上传
      handleSuccess(res, file, fileList, type) {
        // type 1 图片  2附件
        let dataObject = res;
        this.fileList = fileList;
        let fileURL = config.qiniuBucketURLPrefix + "/" + dataObject.key;
        const data = {};
        data.url = fileURL;
        data.name = file.name;
        this.imageUrl = data;
        if (type == 1) {
          this.ruleForm.picture.push(data);
          this.previewList.push(fileURL);
        } else {
          this.ruleForm.attachment.push(data);
        }
      },
      beforeUpload(file) {
        return new Promise((resolve, reject) => {
          if (file.size <= this.maxSize) {
            resolve(file);
          } else {
            this.$message.error("文件大小超过限制100M");
            reject();
          }
        });
      },
      // 获取七牛云token
      getuptoken() {
        this.uptoken = this.$store.state.imWorkbench.UploadToken.upToken
        this.initQiniu(this.uptoken);
      },

      initQiniu(uptoken) {
        var options = {
          // bucket所在区域，这里是华北区。ECN, SCN, NCN, NA, ASG，分别对应七牛云的：华东，华南，华北，北美，新加坡 5 个区域
          region: "SCN",
          uptoken: this.uptoken,
          uptokenURL: "https://[yourserver.com]/api/uptoken",
          uptokenFunc: function () { },
          domain: "https://source.fungsong.com",
          shouldUseQiniuFileName: false,
        };
        // 将七牛云相关配置初始化进本sdk
        init(options);
        this.uploadAction = uploadURLFromRegionCode(config.qiniuRegion);
        this.uploadUrl = uploadURLFromRegionCode(config.qiniuRegion);
        this.uploadData = {
          token: uptoken,
        };
      },

      // 添加回复
      async addReply() {
        if (this.reply) {
          const data = {
            tagName: this.reply,
            userId: this.$store.state.imWorkbench.userId,
          }
          await addTags(data);
          this.$modal.msgSuccess("添加成功");
        }
        await this.getCommentList();
        this.addComment = false;
      }
    },
  };
</script>

<style lang="scss" scoped>
  .file {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;

    >div {
      padding: 4px 10px;
      border: 1px solid #dddfe5;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .picture_list {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;

    >div {
      position: relative;
    }

    .del_box {
      position: absolute;
      right: -6px;
      top: -6px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background: red;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .attachment_list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    >div {
      display: flex;
      gap: 8px;
      justify-content: space-between;

      div {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .tag_list {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  ::v-deep .evaluate-dialog {
    background: rgba(250, 251, 252, 1);

    .el-dialog__header {
      display: flex;
      padding: 20px 20px 10px;

      .el-dialog__headerbtn {
        top: 8px;
        right: 4px;
      }
    }

    .el-dialog__body {
      padding: 10px 20px 0px 20px;

      .el-textarea__inner {
        border: 0px;
        min-height: 200px !important;
      }

      .tag {
        text-align: left;
        margin-bottom: 20px;

        .title {
          margin-bottom: 12px;
          color: rgba(27, 27, 27, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
          line-height: 13px;
        }

        .tag_reply {
          display: flex;
        }

        .tag_operate {
          display: flex;
          gap: 16px;
          margin-left: auto;

          img {
            width: 24px;
            height: 24px;
            margin-top: 2px;
          }
        }

        .tag_list {
          span {
            border-radius: 100px;
            background: rgba(86, 105, 236, 0.05);
            padding: 6px 16px;
            display: flex;
            color: rgb(86, 105, 236);
            font-family: "Noto Sans SC";
            font-weight: 400;
            font-size: 14px;
            line-height: 13px;
            align-items: center;
            border-color: rgb(242, 243, 251)
          }

          .active {
            color: rgb(86, 105, 236);
            background: rgba(86, 105, 236, 0.05);
          }

          .die {
            color: rgb(248, 62, 69);
            background: rgba(248, 62, 69, 0.05);
          }

          .tag_add {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            padding: 6px 16px;
            border-radius: 100px;

            img {
              width: 16px;
              height: 16px;
              margin-left: 8px;
            }

          }
        }
      }

      .file>div {
        width: 136px;
        height: 58px;
        border-radius: 8px;
        border: 1px dashed rgba(199, 199, 201, 1);
        box-sizing: border-box;
        background: rgba(250, 251, 252, 1);
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }

    .el-dialog__footer {
      .dialog-footer {
        display: flex;
        justify-content: flex-end;

        .cancel {
          width: 84px;
          height: 36px;
          border-radius: 4px;
          border: 1px solid rgba(243, 244, 246, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(132, 132, 132, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
        }

        .submit {
          width: 84px;
          height: 36px;
          border-radius: 4px;
          background: rgba(86, 105, 236, 1);
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 1);
          font-family: "Noto Sans SC";
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }

  .dialog-footer>button {
    width: 84px;
    height: 36px;
  }
</style>